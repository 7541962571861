/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
// Error Popup Actions
export const ERROR_APPEAR = 'ERROR_APPEAR';
export const ERROR_CLEAR = 'ERROR_CLEAR';
// Login Actions
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
// Picking List Actions
export const PICKING_LIST_UPDATE = 'PICKING_LIST_UPDATE';
// Picking List Dropdown Actions
export const PICKING_LIST_DROPDOWN_SELECTED_LOCATION_UPDATE =
  'PICKING_LIST_DROPDOWN_SELECTED_LOCATION_UPDATE';
// Column Configuration Actions
export const COLUMN_CONFIGURATION_RESET = 'COLUMN_CONFIGURATION_RESET';
export const COLUMN_CONFIGURATION_LOAD_COLUMNS =
  'COLUMN_CONFIGURATION_LOAD_COLUMNS';
export const COLUMN_CONFIGURATION_UPDATE_COLUMN =
  'COLUMN_CONFIGURATION_UPDATE_COLUMN';
export const COLUMN_CONFIGURATION_UPDATE_VM_COLUMNS_CHANGE =
  'COLUMN_CONFIGURATION_UPDATE_VM_COLUMNS_CHANGE';
// Hide offline locations actions
export const SET_ROUTE_HIDE_OFFLINE_LOCATIONS = 'SET_HIDE_OFFLINE_LOCATIONS';
// Hide dated products actions
export const SET_ROUTE_HIDE_DATED_PRODUCTS = 'SET_HIDE_DATED_PRODUCTS';
