import { PICKING_LIST_UPDATE } from '../constants';

const initialState = { columns: {}, timestamp: null };

const pickingList = (state = initialState, action) => {
  switch (action.type) {
    case PICKING_LIST_UPDATE:
      return {
        ...state,
        columns: {
          ...state.columns,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default pickingList;
