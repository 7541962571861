/* eslint-disable import/prefer-default-export */
import moment from 'moment';

import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
} from '../constants';

import queryIntl from './intl.graphql';

export const getIntlData = () => (dispatch, getState) => getState().intl;

export function setLocale({ locale }) {
  return async (dispatch, getState, { client, history }) => {
    dispatch({
      type: SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      // WARNING !!
      // do not use client.networkInterface except you want skip Apollo store
      // use client.query if you want benefit from Apollo caching mechanisms
      const { data } = await client.query({
        query: queryIntl,
        variables: { locale },
      });
      const messages = data.intl.reduce((msgs, msg) => {
        msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
        return msgs;
      }, {});
      dispatch({
        type: SET_LOCALE_SUCCESS,
        payload: {
          locale,
          messages,
        },
      });

      // remember locale for every new request
      if (process.env.BROWSER) {
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `lang=${locale};path=/;max-age=${maxAge};SameSite=Lax`;
        const {
          location: { pathname, search },
        } = history;
        // This is what triggers a page reload when changing language
        history.replace(`${pathname}${search}`);
      }

      // Set momentjs locale
      moment.locale(locale);

      // Return what we expect store.intl will look like
      return {
        locale,
        messages: {
          [locale]: messages,
        },
      };
    } catch (error) {
      dispatch({
        type: SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
      return null;
    }
  };
}
