import { ERROR_APPEAR, ERROR_CLEAR } from '../constants';

const defaultState = { showError: false };

const errors = (state = defaultState, action) => {
  switch (action.type) {
    case ERROR_APPEAR:
      return action.payload;
    case ERROR_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default errors;
