import { ApolloClient } from '@apollo/client/core';
import apolloLogger from 'apollo-link-logger';
import { onError } from 'apollo-link-error';
import { from as apolloLinkFrom } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import createCache from './createCache';
import { logout } from '../../actions/login';

// storeContainer is a workaround for setting the store. The next version of
// apollo client supports setting the link thorugh method call, but currently
// setting the link by public member causes issues. The store container is
// initially an empty object, which will a moment later have the store in it.
export function generateLink(storeContainer) {
  return apolloLinkFrom([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
          if (message === 'Unauthorized' && storeContainer.store) {
            storeContainer.store.dispatch(logout());
          }
        });
      }
      if (networkError) {
        console.warn(`[Network error]: ${networkError}`);
      }
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    // @ts-ignore
    createUploadLink({
      uri: '/graphql',
      credentials: 'include',
    }),
  ]);
}

const cache = createCache();

export default function createApolloClient(storeContainer) {
  return new ApolloClient({
    link: generateLink(storeContainer),
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
