import {
  COLUMN_CONFIGURATION_LOAD_COLUMNS,
  COLUMN_CONFIGURATION_UPDATE_COLUMN,
  COLUMN_CONFIGURATION_RESET,
  COLUMN_CONFIGURATION_UPDATE_VM_COLUMNS_CHANGE,
} from '../constants';

const INITIAL_STATE = {
  columns: {},
  vendingMachineColumnsChange: {},
};
const columnConfiguration = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COLUMN_CONFIGURATION_LOAD_COLUMNS:
      return {
        ...state,
        ...action.payload,
        vendingMachineColumnsChange: {},
      };
    case COLUMN_CONFIGURATION_RESET:
      return INITIAL_STATE;
    case COLUMN_CONFIGURATION_UPDATE_COLUMN:
      return {
        ...state,
        columns: {
          ...state.columns,
          ...action.payload.columns,
        },
      };
    case COLUMN_CONFIGURATION_UPDATE_VM_COLUMNS_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default columnConfiguration;
