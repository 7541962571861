import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../assets/material-ui/theme';

const intlErrorHandler = error => {
  if (process.env.NODE_ENV !== 'production') {
    if (error.code === 'MISSING_TRANSLATION') {
      // We use default message, no need to tell us if one is missing.
      return;
    }
    console.error(error);
  }
};

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  // Integrate Redux
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  storeSubscription: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  // Apollo Client
  client: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // ReactIntl
  intlData: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    messages: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render() {
    // Here, we are at universe level, sure? ;-)
    const { client, insertCss, store, intlData } = this.props.context;
    const localeMessages =
      (intlData.messages && intlData.messages[intlData.locale]) || {};

    return (
      <StyleContext.Provider value={{ insertCss }}>
        <ThemeProvider theme={theme}>
          <ReduxProvider store={store}>
            <ApolloProvider client={client}>
              <IntlProvider
                locale={intlData.locale}
                key={intlData.locale}
                messages={localeMessages}
                onError={intlErrorHandler}
                textComponent="span"
              >
                {this.props.children}
              </IntlProvider>
            </ApolloProvider>
          </ReduxProvider>
        </ThemeProvider>
      </StyleContext.Provider>
    );
  }
}

export default App;
