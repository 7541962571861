import { PICKING_LIST_DROPDOWN_SELECTED_LOCATION_UPDATE } from '../constants';

const pickingListDropdown = (state = {}, action) => {
  switch (action.type) {
    case PICKING_LIST_DROPDOWN_SELECTED_LOCATION_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export default pickingListDropdown;
