import { LOGIN, LOGOUT } from '../constants';
import { firebaseSetupUser } from '../lib/firebase';

export function fetchCurrentUser(fetchUserMutation) {
  return async dispatch => {
    const user = await fetchUserMutation();

    await dispatch({
      type: LOGIN,
      payload: (user && user.data && user.data.currentUser) || null,
    });

    firebaseSetupUser();
  };
}

// Tries logging in, returns status code so that page
// can display error if not successful.
export function login(username, password) {
  return async (dispatch, getState, { fetch }) => {
    try {
      const response = await fetch(`/login`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      });

      return response.status;
    } catch (error) {
      return 500;
    }
  };
}

export function logout() {
  return async (dispatch, getState, { apolloClient, history, fetch }) => {
    // Clear user from redux store
    dispatch({ type: LOGOUT });

    // Clear JWT from cookies on server side
    fetch(`/logout`, { method: 'GET' });

    // Clear Apollo Cache
    await apolloClient.resetStore();

    firebaseSetupUser();

    history.push('/login');
  };
}

export default logout;
