import { LOGIN, LOGOUT } from '../constants';

export default function currentUser(state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}
