import { SET_ROUTE_HIDE_OFFLINE_LOCATIONS } from '../constants';

export default function routeHideOfflineLocations(state = false, action) {
  switch (action.type) {
    case SET_ROUTE_HIDE_OFFLINE_LOCATIONS:
      return action.payload;
    default:
      return state;
  }
}
