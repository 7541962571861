import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import errors from './errors';
import user from './user';
import currentUser from './currentUser';
import runtime from './runtime';
import intl from './intl';
import pickingList from './pickingList';
import pickingListDropdown from './pickingListDropdown';
import columnConfiguration from './columnConfiguration';
import routeHideOfflineLocations from './routeHideOfflineLocations';
import routeHideDatedProducts from './routeHideDatedProducts';

export default function createRootReducer() {
  return combineReducers({
    currentUser,
    errors,
    form: formReducer,
    intl,
    user,
    pickingList,
    pickingListDropdown,
    columnConfiguration,
    runtime,
    routeHideOfflineLocations,
    routeHideDatedProducts,
  });
}
