import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#00b0e0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#67ca8f',
      contrastText: '#fff',
    },
    text: {
      // This matches react-toolbox.
      // Maybe we can remove it once react-toolbox is deprecated
      secondary: 'rgba(0, 0, 0, 0.26)',
    },
  },
  overrides: {
    // These styles make the Card component to match react-toolbox.
    MuiCard: {
      root: {
        boxShadow:
          '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
        borderRadius: '2px',
        fontSize: '0.875em',
      },
    },
    // Make form control consistent
    MuiFormControl: {
      root: {
        width: '100%',
        justifyContent: 'center',
        padding: '20px 0',
      },
    },
    MuiInputAdornment: {
      root: {
        color: 'rgba(0,0,0,.22)',
      },
    },
    // Make consistent underline accross all INPUT components
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(0,0,0,.12)',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid rgba(0,0,0,.12)',
        },
      },
    },
    // Make consistent label position accross all INPUT components
    MuiInputLabel: {
      formControl: {
        top: '16px',
      },
    },
    // Table Head
    MuiTableHead: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
});

export default theme;
