import { InMemoryCache } from '@apollo/client/cache';
import { defaultDataIdFromObject } from '@apollo/client';

export default function createCache() {
  // https://www.apollographql.com/docs/react/basics/caching.html#configuration
  return new InMemoryCache({
    dataIdFromObject(responseObject) {
      // eslint-disable-next-line no-underscore-dangle
      switch (responseObject.__typename) {
        case 'IntlMessage':
          // Do not use id as identifier for message because it's not unique between languages
          // instead instruct cache to build path and index identifier for cache key
          return null;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
    possibleTypes: {
      Events: [
        'PurchaseEvent',
        'SoldOutEvent',
        'ConfigChangeEvent',
        'RefillEvent',
        'TestDispenseEvent',
        'SalesCounterResetEvent',
        'UnknownEvent',
      ],
      Export: ['LocationPurchasesExport', 'UnknownExport'],
    },
  });
}
