import { initializeApp as firebaseInitializeApp } from 'firebase/app';
import {
  getAnalytics as firebaseGetAnalytics,
  setUserId as firebaseSetUserId,
  setUserProperties as firebaseSetUserProperties,
  logEvent as firebaseLogEvent,
} from 'firebase/analytics';
import { createPath } from 'history/PathUtils';

let firebaseAnalytics = null;
let storeRef = null;

export const firebaseSetupUser = () => {
  if (!firebaseAnalytics) {
    return;
  }
  const currentUser = storeRef.getState().currentUser || {};
  firebaseSetUserId(firebaseAnalytics, currentUser.id || null);
  firebaseSetUserProperties(firebaseAnalytics, {
    operator_id: currentUser.operatorId || null,
  });
};

export const initializeFirebase = store => {
  const firebaseConfig = window.App.state.runtime.firebaseConfig;
  if (!firebaseConfig || !firebaseConfig.appId) {
    return;
  }
  const firebaseAppInstance = firebaseInitializeApp({
    apiKey: firebaseConfig.apiKey,
    projectId: firebaseConfig.projectId,
    appId: firebaseConfig.appId,
    measurementId: firebaseConfig.measurementId,
  });
  firebaseAnalytics = firebaseGetAnalytics(firebaseAppInstance);
  storeRef = store;

  firebaseSetupUser();
};

export const firebasePageView = location => {
  if (!firebaseAnalytics) {
    return;
  }
  firebaseLogEvent(firebaseAnalytics, 'page_view', {
    page_path: createPath(location),
  });
};
